<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <CustomerFormVue
        ref="form"
        class="pa-4"
        v-model="form"
        @onCompanyCreate="onCompanyCreate"
        @onCompanyList="onCompanyList"
      ></CustomerFormVue>
    </template>
  </AView>
</template>

<script>
import CustomerFormVue from "../../../../../components/wad/molecules/customer/CustomerForm.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import { GraphUI } from "../../../GrapUI.config.js";
import { CustomerCompanyCreation, CustomerCompanyList } from "../app.gh.js";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    CustomerFormVue,
    AView,
  },
  data: () => ({
    form: {},
    scene: GraphUI.scene,
    actions: [
      {
        name: "Create",
        description: "Will create new Customer",
        icon: "mdi-check",
        color: "#23842a",
        on: "onCreate",
      },
      {
        name: "Close",
        description: "Will close this window",
        icon: "mdi-trash-can-outline",
        color: "#0e1721",
        on: "onClose",
      },
    ],
  }),
  created() {
    console.log("CUSTOMER CREATED==========", this.value.isFocused);
  },

  computed: {},

  methods: {
    onFocus(data) {
      console.log("=============Data: ", data);
    },
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("CustomerStore/CreateNewCustomer", {
          from: this.value,
          newCustomer: this.form,
        });
    },
    onCompanyList() {
      this.scene.add(this.value, CustomerCompanyList, {});
    },
    async onCompanyCreate() {
      await this.scene.add(this.value, CustomerCompanyCreation, {});
    },
  },
};
</script>