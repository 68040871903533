<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <!-- <FormSectionVue
      :label="'NEW CUSTOMER'"
      icon="mdi-office-building"
      class="pt-0 mt-0"
    ></FormSectionVue> -->
    <!-- Customer Name -->
    <HelpFormInputVue
      dark
      :label="TEXT.CUSTOMER_NAME_LABEL_TEXT"
      :helpText="TEXT.CUSTOMER_NAME_HELP_TEXT"
      :rules="nameRules"
      required
      v-model="form.name"
    ></HelpFormInputVue>

    <!-- Customer E-mail -->
    <HelpFormInputVue
      dark
      :label="TEXT.CUSTOMER_EMAIL_LABEL_TEXT"
      :rules="emailRules"
      :helpText="TEXT.CUSTOMER_EMAIL_HELP_TEXT"
      required
      v-model="form.email"
    ></HelpFormInputVue>

    <FormSectionVue
      :label="'CUSTOMER\'S COMPANY'"
      icon="mdi-office-building"
      :actions="actions"
      @onCompanyCreate="() => $emit('onCompanyCreate')"
      @onCompanyList="() => $emit('onCompanyList')"
    ></FormSectionVue>

    <SelectorCustomerCompanyVue
      autoload
      v-model="form.company"
      dark
    ></SelectorCustomerCompanyVue>
  </v-form>
</template>


<script>
import SelectorCustomerCompanyVue from "../../atoms/common/autocompletes/SelectorCustomerCompany.vue";
import FormSectionVue from "../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import { CUSTOMERS_TEXT } from "../../../constants/TEXT/Leads.text";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //DEFAULT
      TEXT: CUSTOMERS_TEXT.NEW_CUSTOMER_FORM,

      // Form
      pValid: this.valid,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],

      actions: [
        {
          name: "New Company",
          description: "Will create new Company",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCompanyCreate",
        },
        {
          name: "Companies List",
          description: "Will open a companies list",
          icon: "mdi-office-building-outline",
          color: "#0e1721",
          on: "onCompanyList",
        },
      ],

      form: {
        name: "",
        email: "",
        description: "",
        newCompany: {},
        company: {},
      },
    };
  },
  components: {
    HelpFormInputVue,
    SelectorCustomerCompanyVue,
    FormSectionVue,
  },

  methods: {
    validate() {
      return (
        this.$refs.form.validate() &
        +(this.companyTabs === 0 ? this.$refs.companyForm.validate() : true)
      );
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.companyForm.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.$refs.companyForm.resetValidation();
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>